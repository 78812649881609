.flex-spacer {
  flex-grow: 1;
}

.footer-top {
  .ui.header {
    display: block;
    color: white;
  }
}

a.logo-link {
  margin-left: 1rem;
  display: block;

  img {
    max-height: 3.5rem;
    height: 100%;
  }
}
